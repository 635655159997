import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faSignInAlt, faUserPlus } from "@fortawesome/free-solid-svg-icons"; // Correct import

function IndexStaticPage() {
  return (
    <>
      <section className="hero is-fullheight is-fullwidth is-dark">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="columns is-vcentered-desktop is-multiline">
              <div className="column is-12">
                <figure className="image is-128x128 is-inline-block">
                  <img
                    src="/static/logo_symbol.png"
                    style={{ width: "250px" }}
                    alt="Fitness Model"
                  />
                </figure>
              </div>
              <div className="column is-12-mobile is-12-tablet is-8-desktop is-offset-2-desktop">
                <h1 className="title is-1 has-text-white">Fitness App</h1>
                <p className="subtitle is-4 has-text-white my-2 mb-5">
                  Achieve your fitness goals with personalized workouts, diet
                  plans, and real-time tracking. Join our community of fitness
                  enthusiasts today!
                </p>
                <div className="buttons is-centered">
                  <Link
                    to="/login"
                    className="button is-primary is-medium mr-2"
                  >
                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                    Login
                  </Link>
                  <Link
                    to="/register"
                    className="button is-link is-medium ml-2"
                  >
                    <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                    Sign Up
                  </Link>
                </div>
              </div>
             <div className="column mt-4 is-6-desktop is-12-tablet is-12-mobile has-text-white">
                &copy; 2024 Fitness App. All rights reserved.
              </div>
              <div className="column mt-4 is-6-desktop is-12-tablet is-12-mobile">
                <div>
                  <Link
                    to="https://www.facebook.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="icon is-size-4 has-text-white mr-4"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                  <Link
                    to="https://www.twitter.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="icon is-size-4 has-text-white mr-4"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                  <Link
                    to="https://www.instagram.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="icon is-size-4 has-text-white"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                </div>
              </div>
             </div>
            </div>
          </div>
      </section>
    </>
  );
}

export default IndexStaticPage;
