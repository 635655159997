import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faGauge, faMap, faPlay, faTable, faDumbbell, faVideo } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';

import Layout from '../../../../../Menu/Layout';
import { getVideoCategoryDetailAPI } from '../../../../../../API/VideoCategory';
import { getVideoCollectionDetailAPI } from '../../../../../../API/VideoCollection';
import { getVideoContentListAPI } from '../../../../../../API/VideoContent';
import FormErrorBox from '../../../../../Reusable/FormErrorBox';
import PageLoadingContent from '../../../../../Reusable/PageLoadingContent';
import { topAlertMessageState, topAlertStatusState } from '../../../../../../AppState';
import { EXERCISE_CATEGORY_OPTIONS, EXERCISE_MOMENT_TYPE_OPTIONS } from '../../../../../../Constants/FieldOptions';
import {
    VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
    VIDEO_CONTENT_THUMBNAIL_TYPE_EXTERNAL_URL,
    VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
    VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE,
    VIDEO_CONTENT_VIDEO_TYPE_VIMEO
} from "../../../../../../Constants/App";


const MemberVideoCollectionContentList = () => {
    ////
    //// URL Parameters.
    ////

    const { vcatid, vcollid, vconid } = useParams();

    ////
    //// Global state.
    ////

    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);

    ////
    //// Component states.
    ////

    const [errors, setErrors] = useState({});
    const [videoCategory, setVideoCategory] = useState({});
    const [videoCollection, setVideoCollection] = useState({});
    const [videoContentList, setVideoContentList] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);

    ////
    //// API.
    ////

    // --- Video Category ---

    const onVideoCategoryDetailSuccess = (response) => {
        try {
            console.log("onVideoCategoryDetailSuccess | response:", response);
            if (response) {
                setVideoCategory(response);
            }
        } catch (error) {
            console.error("Error fetching video list:", error);
            setErrors({
                message: "Failed to fetch category detail. Please try again later.",
            });
        } finally {
            setIsFetching(false);
        }
    };

    const onVideoCategoryDetailError = (apiErr) => {
        console.log(apiErr);
        setIsFetching(false);
        setErrors({
            message: "Failed to fetch video list. Please try again later.",
        });
    };

    // --- Video Collection ---

    const onVideoCollectionDetailSuccess = (response) => {
        try {
            console.log("onVideoCollectionDetailSuccess | response:", response);
            if (response) {
                setVideoCollection(response);
            }
        } catch (error) {
            console.error("Error fetching video list:", error);
            setErrors({
                message: "Failed to fetch category detail. Please try again later.",
            });
        } finally {
            setIsFetching(false);
        }
    };

    const onVideoCollectionDetailError = (apiErr) => {
        console.log(apiErr);
        setIsFetching(false);
        setErrors({
            message: "Failed to fetch video list. Please try again later.",
        });
    };

    // --- Video Content ---

    const onVideoCollectionContentListSuccess = (response) => {
        try {
            if (response && response.results) {
                setVideoContentList(response.results);
            }
        } catch (error) {
            console.error("Error fetching video list:", error);
            setErrors({
                message: "Failed to fetch video list. Please try again later.",
            });
        } finally {
            setIsFetching(false);
        }
    };

    const onVideoCollectionContentListError = (apiErr) => {
        console.log(apiErr);
        setIsFetching(false);
        setErrors({
            message: "Failed to fetch video list. Please try again later.",
        });
    };

    ////
    //// Event handling.
    ////

    const fetchList = () => {
        setIsFetching(true);
        setErrors({});

        let params = new Map();
        params.set("sort_field", "created"); // Sorting
        params.set("sort_order", -1); // Sorting - descending, meaning most recent start date to oldest start date.

        params.set("video_collection_id", vconid);

        console.log("params:", params);

        getVideoContentListAPI(
            params,
            onVideoCollectionContentListSuccess,
            onVideoCollectionContentListError,
        );
    };

    const handleThumbnailClick = (index) => {
        setActiveVideoIndex(index);
    };

    ////
    //// Misc.
    ////

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0); // Start the page at the top of the page.

            // Get the video category detail.
            getVideoCategoryDetailAPI(
                vcatid,
                onVideoCategoryDetailSuccess,
                onVideoCategoryDetailError,
            );

            // Get the video collection detail.
            getVideoCollectionDetailAPI(
                vcollid,
                onVideoCollectionDetailSuccess,
                onVideoCollectionDetailError,
            );

            // Get the video content list.
            fetchList();
        }

        return () => {
            mounted = false;
        };
    }, [vcatid, vcollid, vconid]);

    ////
    //// Component rendering.
    ////

    const breadcrumbItems = {
        items: [
            { text: 'Dashboard', link: '/dashboard', isActive: false, icon: faGauge },
            { text: (videoCategory != undefined && videoCategory != null && videoCategory != "") ? "Categories (" + videoCategory.name + ")" : "-", link: '/video-categories', icon: faDumbbell, isActive: false },
            { text: (videoCollection != undefined && videoCollection != null && videoCollection != "") ? "Video Collection (" + videoCollection.name + ")" : "-", link: '#', icon: faVideo, isActive: true },
        ],
        mobileBackLinkItems: {
            link: '/dashboard',
            text: 'Back to Dashboard',
            icon: faArrowLeft,
        },
    };

    return (
        <Layout breadcrumbItems={breadcrumbItems}>
            <div className="box">
                <div className="container is-fluid p-0">
                    {isFetching ? (
                        <PageLoadingContent displayMessage="Please wait..." />
                    ) : (
                        <>
                            <FormErrorBox errors={errors} />
                            {videoContentList.length > 0 ? (
                                <>
                                    <div className="columns is-multiline">
                                        <div className="hero-body p-4">
                                            <div className="columns is-multiline mb-0">
                                                <div className="column">
                                                    <div className="is-flex-desktop is-justify-content-space-between">
                                                        <div className="">
                                                            <h5 className="is-size-7 has-text-weight-semibold">
                                                                VIDEO COLLECTION
                                                            </h5>
                                                            <h5 className="is-size-4 has-text-weight-bold">
                                                                {videoContentList[activeVideoIndex]?.categoryName || 'Category Name'}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="columns is-multiline mb-0 has-background-black box has-text-white has-text-centered" style={{ borderRadius: "20px" }}>
                                        <div className="column mb-0 is-2-tablet is-3-desktop is-4-widescreen is-5-fullhd">
                                        </div>
                                        <div className="column mb-0 is-8-tablet is-6-desktop is-4-widescreen is-2-fullhd">
                                            {/* Simple Storage Service (S3) */}
                                            {videoContentList[activeVideoIndex]?.videoType === VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE &&
                                                <ReactPlayer
                                                    url={videoContentList[activeVideoIndex]?.videoObjectUrl}
                                                    controls={true}
                                                    width="100%"
                                                    height="400px"
                                                />
                                            }
                                            {/* YouTube, Vimeo, etc */}
                                            {(videoContentList[activeVideoIndex]?.videoType === VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE || videoContentList[activeVideoIndex]?.videoType === VIDEO_CONTENT_VIDEO_TYPE_VIMEO) &&
                                                <ReactPlayer
                                                    url={videoContentList[activeVideoIndex]?.videoUrl}
                                                    controls={true}
                                                    width="100%"
                                                    height="400px"
                                                />
                                            }
                                        </div>
                                        <div className="column mb-0 is-2-tablet is-3-desktop is-4-widescreen is-5-fullhd">
                                        </div>
                                    </div>
                                    <div className="columns is-multiline mb-0">
                                        <div className="column mb-0 is-full">
                                            <div className="has-background-black border-radius"></div>
                                            <h5 className="is-size-5 has-text-weight-semibold mt-5">
                                                {videoContentList[activeVideoIndex]?.name || 'No title found'}
                                            </h5>
                                        </div>
                                    </div>
                                    {/* Display thumbnails for other videos */}
                                    <div className="columns is-multiline mb-0 mt-5">
                                        <div className="column mb-0">
                                            <div className="is-flex is-justify-content-space-between">
                                                <h3 className="is-size-6 has-text-weight-semibold has-text-grey-light">
                                                    Videos
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mt-0 mb-1" />
                                    <div className="columns is-multiline mb-0">
                                        {videoContentList.map((video, index) => (
                                            <div
                                                className="column mb-0 is-3"
                                                key={video.id}
                                                onClick={() => handleThumbnailClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <div
                                                    className={`border-radius ${
                                                        index === activeVideoIndex ? 'playing-video' : ''
                                                    }`}
                                                >
                                                    <div>
                                                        {index === activeVideoIndex && (
                                                            <FontAwesomeIcon className="icon" icon={faPlay} />
                                                        )}
                                                        {video.thumbnailType === VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE &&
                                                            <img
                                                                className="border-radius"
                                                                src={video.thumbnailObjectUrl}
                                                                alt={video.name}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '200px',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                        }
                                                        {video.thumbnailType === VIDEO_CONTENT_THUMBNAIL_TYPE_EXTERNAL_URL &&
                                                            <img
                                                                className="border-radius"
                                                                src={video.thumbnailUrl}
                                                                alt={video.name}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '200px',
                                                                    objectFit: 'cover',
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <section className="hero is-medium has-background-white-ter">
                                    <div className="hero-body">
                                        <p className="title">
                                            <FontAwesomeIcon className="fas" icon={faTable} />
                                            &nbsp;No Videos
                                        </p>
                                        <p className="subtitle">
                                            No videos found at the moment. Please check back later!
                                        </p>
                                    </div>
                                </section>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default MemberVideoCollectionContentList;
