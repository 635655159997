import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faPersonWalking,
  faRankingStar,
  faChartLine,
  faHeartbeat,
  faRoad,
  faFire,
  faFilterCircleXmark,
  faArrowLeft,
  faUsers,
  faEye,
  faPencil,
  faTrashCan,
  faPlus,
  faGauge,
  faArrowRight,
  faTable,
  faArrowUpRightFromSquare,
  faRefresh,
  faFilter,
  faSearch,
  faUser,
  faShoePrints,
  faMapMarkerAlt,
  faLock,
  faShoppingBag,
  faStar,
  faChild,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../../Reusable/FormErrorBox";
import { getMySummaryAPI } from "../../../../API/Biometric";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
  dataPointFilterShowState,
  dataPointFilterTemporarySearchTextState,
  dataPointFilterActualSearchTextState,
  dataPointFilterSortState,
  dataPointFilterStatusState,
  dataPointFilterIsHeartRateState,
  dataPointFilterIsStepCountDeltaState,
} from "../../../../AppState";
import PageLoadingContent from "../../../Reusable/PageLoadingContent";
import BarChart from "../../../Reusable/Charts/Bar";
import Layout from "../../../Menu/Layout";
import { faFreeCodeCamp } from "@fortawesome/free-brands-svg-icons";

function MemberSummary() {
  ////
  //// Global state.
  ////
  const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////
  const [errors, setErrors] = useState({});
  const [datum, setDatum] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [showBiometrics, setShowBiometrics] = useState(true); // Toggle state for biometrics

  ////
  //// API.
  ////
  function onSummarySuccess(response) {
    console.log("onSummarySuccess: Starting...");
    console.log("onSummarySuccess: response:", response);
    setDatum(response);
  }

  function onSummaryError(apiErr) {
    console.log("onSummaryError: Starting...");
    setErrors(apiErr);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onSummaryDone() {
    console.log("onSummaryDone: Starting...");
    setFetching(false);
  }

  ////
  //// BREADCRUMB
  ////
  const breadcrumbItems = {
    items: [
      { text: "Dashboard", link: "/dashboard", isActive: false, icon: faGauge },
      {
        text: "Biometrics",
        link: "/biometrics",
        icon: faHeartbeat,
        isActive: false,
      },
      { text: "My Summary", link: "#", icon: faRankingStar, isActive: true },
    ],
    mobileBackLinkItems: {
      link: "/biometrics",
      text: "Back to Biometrics",
      icon: faArrowLeft,
    },
  };

  ////
  //// Event handling.
  ////
  const getDatum = (user) => {
    if (user !== undefined && user !== null && user !== "") {
      const connectedApps = user.healthAppConnections
        ? user.healthAppConnections.filter(
            (app) => app.connected === "CONNECTED"
          )
        : [];

      if (connectedApps.length > 0) {
        setFetching(true);
        setErrors({});

        getMySummaryAPI(
          user.id,
          onSummarySuccess,
          onSummaryError,
          onSummaryDone
        );
      } else {
        console.log("user does not have a device, prevented pulling data.");
      }
    }
  };

  const toggleBiometrics = () => setShowBiometrics(!showBiometrics);

  ////
  //// Misc.
  ////
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
      getDatum(currentUser);
    }

    return () => {
      mounted = false;
    };
  }, [currentUser]);

  ////
  //// Helper Functions
  ////
  const formatToHours = (end) => {
    return moment(end).format("ha"); // Formats the end to hour with am/pm
  };

  const transformData = (barData, label, text, timeframe, mode, dataType) => {
    const formatFunction = (item) => {
      switch (timeframe) {
        case "hours":
          return formatToHours(getDateForFormatting(item, timeframe));
        case "week":
          return moment(getDateForFormatting(item, timeframe)).format("ddd");
        case "month":
          return moment(getDateForFormatting(item, timeframe)).format("MMM D");
        case "year":
          return moment(getDateForFormatting(item, timeframe)).format("MMM YYYY");
        default:
          return formatToHours(getDateForFormatting(item, timeframe));
      }
    };

    const dataset = {
      label: ` ${label}`,
      data: barData.map((item) => item.data[dataType] || 0),
      backgroundColor:
        dataType === "steps"
          ? "#5374DF"
          : dataType === "distance"
          ? "#E1BD67"
          : "#DF5353",
      borderColor:
        dataType === "steps"
          ? "rgba(54, 162, 235, 1)"
          : dataType === "distance"
          ? "#E1BD67"
          : "#DF5353",
      borderWidth: 0,
      borderRadius: 5,
      borderSkipped: false,
    };

    return {
      text: text,
      labels: barData.map(formatFunction),
      datasets: [dataset],
    };
  };

  const getDateForFormatting = (item, timeframe) => {
    if (item.data && item.data.date) {
      return item.data.date;
    }

    if (timeframe === "week" || timeframe === "month" || timeframe === "year") {
      return item.startDate; // Use startDate when date is empty for these timeframes
    }
    return new Date(); // return current date as fallback
  };

  ////
  //// Component rendering.
  ////
  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      <div className="box px-2">
        <div className="columns">
          <div className="column">
            <h1 className="title is-4">
              <FontAwesomeIcon className="fas is-size-4" icon={faStar} />
              &nbsp;My Summary&nbsp;
              <span className="tag is-success is-light">Beta</span>
            </h1>
          </div>
          <div className="column has-text-right">
            <button
              onClick={() => getDatum(currentUser)}
              className="button is-link is-small"
              type="button"
            >
              <FontAwesomeIcon className="mdi" icon={faRefresh} />
              &nbsp;
              <span className="is-hidden-desktop is-hidden-tablet">Refresh</span>
            </button>
          </div>
        </div>

        {isFetching ? (
          <PageLoadingContent displayMessage={"Please wait..."} />
        ) : (
          <>
            <FormErrorBox errors={errors} />
            {datum ? (
              <>
                {showBiometrics ? (
                  <section className="section is-fluid">
                    <div className="columns">
                      <div className="column is-one-fourth">
                        <div className="box">
                          <div className="media">
                            <div className="media-left">
                              <span className="icon">
                                <FontAwesomeIcon className="fas is-size-4" icon={faFreeCodeCamp} />
                              </span>
                            </div>
                            <div title="Calories for today" className="media-content">
                              <p className="title has-text-weight-semibold is-7">Calories (Today)</p>
                              <p className="has-text-weight-semibold subtitle is-6">
                                {datum?.dailySummary?.data?.calories ? Math.round(datum.dailySummary.data.calories) : 0} kcal
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-one-fourth">
                        <div className="box">
                          <div className="media">
                            <div className="media-left">
                              <span className="icon">
                                <FontAwesomeIcon className="fas is-size-4" icon={faChild} />
                              </span>
                            </div>
                            <div title="Calories for today" className="media-content">
                              <p className="title has-text-weight-semibold is-7">Steps Count (Today)</p>
                              <p className="has-text-weight-semibold subtitle is-6">
                                {datum?.dailySummary?.data?.steps ? Math.round(datum.dailySummary.data.steps) : 0} steps
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-one-fourth">
                        <div className="box">
                          <div className="media">
                            <div className="media-left">
                              <span className="icon">
                                <FontAwesomeIcon className="fas is-size-4" icon={faMapMarkedAlt} />
                              </span>
                            </div>
                            <div title="Calories for today" className="media-content">
                              <p className="title has-text-weight-semibold is-7">Distance (Today)</p>
                              <p className="has-text-weight-semibold subtitle is-6">
                                {datum?.dailySummary?.data?.distance ? Math.round(datum.dailySummary.data.distance) : 0} m
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column is-one-fourth">
                        <div className="box">
                          <div className="media">
                            <div className="media-left">
                              <span className="icon">
                                <FontAwesomeIcon className="fas is-size-4" icon={faFreeCodeCamp} />
                              </span>
                            </div>
                            <div title="Calories for today" className="media-content">
                              <p className="title has-text-weight-semibold is-7">Calories (Yesterday)</p>
                              <p className="has-text-weight-semibold subtitle is-6">
                                {datum?.lastDaySummary?.data?.calories ? Math.round(datum.lastDaySummary.data.calories) : 0} kcal
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-one-fourth">
                        <div className="box">
                          <div className="media">
                            <div className="media-left">
                              <span className="icon">
                                <FontAwesomeIcon className="fas is-size-4" icon={faChild} />
                              </span>
                            </div>
                            <div title="Calories for today" className="media-content">
                              <p className="title has-text-weight-semibold is-7">Steps Count (Yesterday)</p>
                              <p className="has-text-weight-semibold subtitle is-6">
                                {datum?.lastDaySummary?.data?.steps ? Math.round(datum.lastDaySummary.data.steps) : 0} steps
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-one-fourth">
                        <div className="box">
                          <div className="media">
                            <div className="media-left">
                              <span className="icon">
                                <FontAwesomeIcon className="fas is-size-4" icon={faMapMarkedAlt} />
                              </span>
                            </div>
                            <div title="Calories for today" className="media-content">
                              <p className="title has-text-weight-semibold is-7">Distance (Yesterday)</p>
                              <p className="has-text-weight-semibold subtitle is-6">
                                {datum?.lastDaySummary?.data?.distance ? Math.round(datum.lastDaySummary.data.distance) : 0} m
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="columns is-multiline">
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.weeklySummaries,
                            "Steps Count",
                            "Steps Count - Week",
                            "week",
                            4,
                            "steps"
                          )}
                        />
                      </div>
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.monthlySummaries,
                            "Steps Count",
                            "Steps Count - Month",
                            "month",
                            4,
                            "steps"
                          )}
                        />
                      </div>
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.yearlySummaries,
                            "Steps Count",
                            "Steps Count - Year",
                            "year",
                            4,
                            "steps"
                          )}
                        />
                      </div>
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.weeklySummaries,
                            "Calories Count",
                            "Calories Count - Week",
                            "week",
                            4,
                            "calories"
                          )}
                        />
                      </div>
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.monthlySummaries,
                            "Calories",
                            "Calories - Month",
                            "month",
                            4,
                            "calories"
                          )}
                        />
                      </div>
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.yearlySummaries,
                            "Calories",
                            "Calories - Year",
                            "year",
                            4,
                            "calories"
                          )}
                        />
                      </div>
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.weeklySummaries,
                            "Distance",
                            "Distance - Week",
                            "week",
                            4,
                            "distance"
                          )}
                        />
                      </div>
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.monthlySummaries,
                            "Distance",
                            "Distance - Month",
                            "month",
                            4,
                            "distance"
                          )}
                        />
                      </div>
                      <div className="column is-one-third">
                        <BarChart
                          data={transformData(
                            datum.yearlySummaries,
                            "Distance",
                            "Distance - Year",
                            "year",
                            4,
                            "distance"
                          )}
                        />
                      </div>
                    </div>
                  </section>
                ) : (
                  <div className="alternative-content">
                    {/* Your alternative content */}
                  </div>
                )}
              </>
            ) : (
              <section className="hero is-medium has-background-white-ter">
                <div className="hero-body">
                  <p className="title">
                    <FontAwesomeIcon className="fas is-size-4" icon={faTable} />
                    &nbsp;No Biometrics
                  </p>
                  <p className="subtitle">
                    You currently have no biometrics data. Please check back later!
                  </p>
                </div>
              </section>
            )}
          </>
        )}

        <div className="columns pt-5">
          <div className="column is-half">
            <Link className="button is-hidden-touch" to={`/biometrics`}>
              <FontAwesomeIcon className="fas is-size-4" icon={faArrowLeft} />
              &nbsp;Back to Biometrics
            </Link>
            <Link className="button is-fullwidth is-hidden-desktop" to={`/biometrics`}>
              <FontAwesomeIcon className="fas is-size-4" icon={faArrowLeft} />
              &nbsp;Back to Biometrics
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MemberSummary;
