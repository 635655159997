import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faGauge,
  faEye,
  faCalendarPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
  currentUserState,
  topAlertMessageState,
  topAlertStatusState,
} from "../../../AppState";
import { postTrainingProgCreateAPI } from "../../../API/trainingProgram";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import Layout from "../../Menu/Layout";

function MemberTrainingProgramAdd() {
  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentuser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
  const [memoptions, setmemoptions] = useState([]);

  const [name, setName] = useState("");
  const [phases, setphases] = useState(4);
  const [weeks, setweeks] = useState(4);
  const [description, setDescription] = useState("");

  ////
  //// Event handling.
  ////

  const onSubmitClick = (e) => {
    setFetching(true);
    setErrors({});
    postTrainingProgCreateAPI(
      {
        name: name,
        description: description,
        phases: parseInt(phases),
        weeks: parseInt(weeks),
        organization_id: currentuser.organizationId,
        user_id: currentuser.id,
        visibility: 2,
      },
      onAddSuccess,
      onAddError,
      onAddDone
    );
  };

  ////
  //// API.
  ////

  // --- VideoCollection Create --- //

  function onAddSuccess(response) {
    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("program  created");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // Redirect the organization to the organization attachments page.
    setForceURL("/training-program/" + response.id + "");
  }

  function onAddError(apiErr) {
    setErrors(apiErr);

    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAddDone() {
    setFetching(false);
  }

  ////
  //// BREADCRUMB
  ////
  const breadcrumbItems = {
    items: [
      { text: "Dashboard", link: "/dashboard", isActive: false, icon: faGauge },
      {
        text: "Training Program",
        link: "/training-program",
        isActive: false,
        icon: faCalendarPlus,
      },
      { text: "New", link: "#", isActive: true, icon: faPlus },
    ],
    mobileBackLinkItems: {
      link: "/training-program",
      text: "Back to Training Program",
      icon: faArrowLeft,
    },
  };

  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
    }

    return () => {
      mounted = false;
    };
  }, []);

  ////
  //// Component rendering.
  ////

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const isThumbnailUploaded =
    thumbnailAttachmentID !== "" || thumbnailURL !== "";

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      {/* Page */}
      <div class="box">
        <div class="columns">
          <div class="column">
            <p class="title is-4">
              <FontAwesomeIcon className="fas" icon={faPlus} />
              &nbsp;Add Training Programs
            </p>
          </div>
          <div class="column has-text-right"></div>
        </div>
        <FormErrorBox errors={errors} />

        <p class="pb-4 mb-5 has-text-grey">
          Please fill out all the required fields before submitting this form.
        </p>

        {isFetching ? (
          <PageLoadingContent displayMessage={"Please wait..."} />
        ) : (
          <>
            <div>
              <>
                <p class="subtitle is-6 mt-5">
                  <FontAwesomeIcon className="fas" icon={faEye} />
                  &nbsp;Information
                </p>
                <hr />
                <div className="columns">
                  <div className="column">
                    <DataDisplayRowText
                      label={" User"}
                      value={currentuser.name}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-justify-content-center">
                    <FormInputField
                      type="number"
                      label="Phases"
                      name="phases"
                      placeholder="phases"
                      value={phases}
                      errorText={errors && errors.phases}
                      onChange={(e) => setphases(e.target.value)}
                      isRequired={true}
                      maxWidth="80px"
                    />
                  </div>
                  <div className="column">
                    <FormInputField
                      type="number"
                      label="Weeks"
                      name="weeks"
                      placeholder="weeks"
                      value={weeks}
                      errorText={errors && errors.weeks}
                      onChange={(e) => setweeks(e.target.value)}
                      isRequired={true}
                      maxWidth="80px"
                    />
                  </div>
                  <div className="column">
                    <FormInputField
                      label="Duration"
                      name="duration"
                      value={phases * weeks + " weeks"}
                      disabled
                      maxWidth="180px"
                    />
                  </div>
                </div>
              </>

              <FormTextareaField
                label="Name"
                name="Name"
                placeholder="name"
                value={name}
                errorText={errors && errors.name}
                onChange={(e) => setName(e.target.value)}
                isRequired={true}
                maxWidth="380px"
              />
              <FormTextareaField
                label="Description"
                name="description"
                placeholder="Description input"
                value={description}
                errorText={errors && errors.description}
                helpText=""
                onChange={(e) => setDescription(e.target.value)}
                isRequired={true}
                maxWidth="380px"
              />

              <div class="columns pt-5">
                <div class="column is-half">
                  <Link class="button is-hidden-touch" to={`/training-program`}>
                    <FontAwesomeIcon className="fas" icon={faArrowLeft} />
                    &nbsp;Back to training programs
                  </Link>
                  <Link
                    class="button is-fullwidth is-hidden-desktop"
                    to={`/training-program`}
                  >
                    <FontAwesomeIcon className="fas" icon={faArrowLeft} />
                    &nbsp;Back to training programs
                  </Link>
                </div>
                <div class="column is-half has-text-right">
                  <Link
                    class="button is-success is-hidden-touch"
                    onClick={onSubmitClick}
                    disabled={
                      !(
                        name &&
                        description &&
                        phases &&
                        weeks &&
                        currentuser.id
                      )
                    }
                  >
                    <FontAwesomeIcon className="fas" icon={faPlus} />
                    &nbsp;Submit
                  </Link>
                  <Link
                    class="button is-success is-fullwidth is-hidden-desktop"
                    onClick={onSubmitClick}
                    disabled={
                      !(
                        name &&
                        description &&
                        phases &&
                        weeks &&
                        currentuser.id
                      )
                    }
                  >
                    <FontAwesomeIcon className="fas" icon={faPlus} />
                    &nbsp;Submit
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default MemberTrainingProgramAdd;
