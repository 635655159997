import {
  faCalendarAlt,
  faClock,
  faEdit,
  faMarker,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TabularMobile = ({
  pointsHistory = [],
  setselectedPOint,
  setshowUpdateModal,
  setshowDeleteModal,
}) => {
  return (
    <>
      {pointsHistory.map((entry, i) => {
        return (
          <div class="pb-2" key={`mobile_tablet_${entry.id}`}>
            <strong>Date:</strong>&nbsp;
            {new Date(entry.date).toLocaleDateString()}
            <br />
            <strong>Time:</strong>&nbsp;
            {new Date(entry.date).toLocaleTimeString()}
            <br />
            <strong>Points:</strong>&nbsp;{entry.value}
            <br />
            <br />
            <div className="buttons is-right">
              <button className="button is-warning is-small mr-1 ml-5">
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => {
                    setselectedPOint(entry);
                    setshowUpdateModal(true);
                  }}
                />
              </button>
              <button className="button is-danger is-small mr-1 ml-1">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => {
                    setselectedPOint(entry);
                    setshowDeleteModal(true);
                  }}
                />
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TabularMobile;
