import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Modal = ({ isOpen, onClose, header, children }) => {
  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen && "is-active"}`}>
      <div class="modal-background"></div>
      <div className="modal-card">
        <div className="modal-card-head ">
          <p class="modal-card-title">{header}</p>

          <button className="button is-small is-left" onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <div className="modal-card-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
