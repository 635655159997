import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faClock,
	faRepeat,
	faTasks,
	faTachometer,
	faPlus,
	faArrowLeft,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faLeaf,
	faEye,
	faIdCard,
	faAddressBook,
	faContactCard,
	faChartPie,
	faCogs,
	faArchive,
	faExclamationTriangle,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import {
	getNutritionPlanDetailAPI,
	deleteNutritionPlanAPI,
} from "../../../API/NutritionPlan";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {topAlertMessageState, topAlertStatusState} from "../../../AppState";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import DataDisplayRowRadio from "../../Reusable/DataDisplayRowRadio";
import FormTextTagRow from "../../Reusable/FormTextTagRow";
import FormTextYesNoRow from "../../Reusable/FormTextYesNoRow";
import FormTextOptionRow from "../../Reusable/FormTextOptionRow";
import DataDisplayRowMultiSelect from "../../Reusable/FormTextOptionRow";
import DataDisplayRowMultiSelectStatic from "../../Reusable/DataDisplayRowMultiSelectStatic";
import DataDisplayRowSelectStatic from "../../Reusable/DataDisplayRowSelectStatic";
import {
	HOME_GYM_EQUIPMENT_OPTIONS,
	HOME_GYM_EQUIPMENT_MAP,
	FEET_WITH_EMPTY_OPTIONS,
	INCHES_WITH_EMPTY_OPTIONS,
	GENDER_WITH_EMPTY_OPTIONS,
	PHYSICAL_ACTIVITY_MAP,
	PHYSICAL_ACTIVITY_WITH_EMPTY_OPTIONS,
	WORKOUT_INTENSITY_WITH_EMPTY_OPTIONS,
	DAYS_PER_WEEK_MAP,
	DAYS_PER_WEEK_WITH_EMPTY_OPTIONS,
	TIME_PER_DAY_MAP,
	TIME_PER_DAY_WITH_EMPTY_OPTIONS,
	MAX_WEEK_MAP,
	MAX_WEEK_WITH_EMPTY_OPTIONS,
	FITNESS_GOAL_MAP,
	FITNESS_GOAL_OPTIONS,
	WORKOUT_PREFERENCE_MAP,
	WORKOUT_PREFERENCE_OPTIONS,
} from "../../../Constants/FieldOptions";
import {
	NUTRITION_GOAL_STATUS_QUEUED,
	NUTRITION_GOAL_STATUS_ACTIVE,
	NUTRITION_GOAL_STATUS_ARCHIVED,
	NUTRITION_GOAL_STATUS_ERROR,
	NUTRITION_GOAL_STATUS_IN_PROGRESS,
	NUTRITION_GOAL_STATUS_PENDING,
	GENDER_OTHER,
	GENDER_MALE,
	GENDER_FEMALE,
} from "../../../Constants/App";
import Layout from "../../Menu/Layout";

function MemberNutritionPlanDetail() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [tabIndex, setTabIndex] = useState(1);
	const [
		selectedNutritionPlanForDeletion,
		setSelectedNutritionPlanForDeletion,
	] = useState(null);

	////
	//// Event handling.
	////

	const onDeleteConfirmButtonClick = () => {
		console.log("onDeleteConfirmButtonClick"); // For debugging purposes only.

		deleteNutritionPlanAPI(
			selectedNutritionPlanForDeletion.id,
			onNutritionPlanDeleteSuccess,
			onNutritionPlanDeleteError,
			onNutritionPlanDeleteDone
		);
		setSelectedNutritionPlanForDeletion(null);
	};

	////
	//// API.
	////

	// --- Detail --- //

	function onNutritionPlanDetailSuccess(response) {
		console.log("onNutritionPlanDetailSuccess: Starting...");
		setDatum(response);
	}

	function onNutritionPlanDetailError(apiErr) {
		console.log("onNutritionPlanDetailError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onNutritionPlanDetailDone() {
		console.log("onNutritionPlanDetailDone: Starting...");
		setFetching(false);
	}

	// --- Delete --- //

	function onNutritionPlanDeleteSuccess(response) {
		console.log("onNutritionPlanDeleteSuccess: Starting..."); // For debugging purposes only.

		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("Nutrition plan deleted");
		setTimeout(() => {
			console.log(
				"onDeleteConfirmButtonClick: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect back to the video categories page.
		setForceURL("/nutrition-plans");
	}

	function onNutritionPlanDeleteError(apiErr) {
		console.log("onNutritionPlanDeleteError: Starting..."); // For debugging purposes only.
		setErrors(apiErr);

		// Update notification.
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			console.log(
				"onNutritionPlanDeleteError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onNutritionPlanDeleteDone() {
		console.log("onNutritionPlanDeleteDone: Starting...");
		setFetching(false);
	}

	////
	//// BREADCRUMB
	////
	const breadcrumbItems = {
		items: [
			{text: "Dashboard", link: "/dashboard", isActive: false, icon: faGauge},
			{
				text: "Nutrition Plans",
				link: "/nutrition-plans",
				icon: faLeaf,
				isActive: false,
			},
			{text: "Detail", link: "#", icon: faEye, isActive: true},
		],
		mobileBackLinkItems: {
			link: "/nutrition-plans",
			text: "Back to Nutrition Plans",
			icon: faArrowLeft,
		},
	};

	const generateGroceryDescription = (groceryList) => {
		const categories = Object.keys(groceryList);
		const categoryCount = categories.length;

		let description =
			"This grocery list is designed to provide you with a wide range of nutrient-rich foods to support your nutrition plan. ";

		if (categoryCount > 0) {
			description += "The list includes ";
			categories.forEach((category, index) => {
				if (index === categoryCount - 1 && categoryCount > 1) {
					description += "and ";
				}
				description += category.toLowerCase();
				if (index < categoryCount - 1) {
					description += ", ";
				} else {
					description += " to ensure balanced and wholesome meals.";
				}
			});
		}

		description +=
			" Feel free to organize your shopping in a way that suits your routine and preferences.";

		return description;
	};

	const renderNutritionPlan = (data) => {
		if (!data) {
			// Handle the case where data is null or undefined
			return <p>No nutrition plan data available.</p>;
		}

		const {groceryList, caloricTargets, instructions, intermittentFasting} =
			data;

		return (
			<div className="nutrition-plan-container">
				<h1>CLIENT NUTRITION PLAN</h1>
				<p>{instructions}</p>

				{/* Grocery List */}

				{groceryList && (
					<>
						<h2>Grocery List:</h2>
						<div className="grocery-list">
							<ol>
								{Object.entries(groceryList).map(([category, items], index) => (
									<li key={index}>
										<strong>
											{category.charAt(0).toUpperCase() + category.slice(1)}:
										</strong>{" "}
										{items.length > 0 ? items.join(", ") : "None"}
									</li>
								))}
							</ol>
						</div>

						<p>{generateGroceryDescription(groceryList)}</p>
					</>
				)}

				{/* Meal Plans for each Week */}
				{caloricTargets &&
					caloricTargets.map(
						(target, weekIndex) =>
							target &&
							target.meals &&
							target.meals.length > 0 && (
								<React.Fragment key={weekIndex}>
									<h2>Meal Plan {target.week}:</h2>
									<div className="meal-plan">
										{target.meals.map(
											(mealDay, dayIndex) =>
												mealDay &&
												mealDay.mealPlans &&
												mealDay.mealPlans.length > 0 && (
													<section className="day" key={dayIndex}>
														<h2>{mealDay.day}</h2>
														{mealDay.mealPlans.map((meal, mealIndex) => (
															<div className="meal" key={mealIndex}>
																<h3>{meal.name}</h3>
																<p>
																	{meal.description}{" "}
																	{meal.calories &&
																		`(Calories: ${meal.calories})`}
																</p>
																<p>
																	<strong>Ingredients:</strong>&nbsp;
																	{meal.ingredients.join(", ")}
																</p>
																<p>
																	<strong>Instructions:</strong>&nbsp;
																	{meal.instructions}
																</p>
															</div>
														))}
													</section>
												)
										)}
									</div>
								</React.Fragment>
							)
					)}

				{caloricTargets && caloricTargets.length === 0 && (
					<p>Meal plans are not available for this period.</p>
				)}

				{intermittentFasting && (
					<>
						<h2>Intermittent Fasting Plan:</h2>
						<p>
							Since you've indicated interest in intermittent fasting, I suggest
							following a {intermittentFasting} fasting approach. This means you
							eat during an 8-hour period and fast for 16 hours. For example,
							you eat from 10 am to 6 pm and fast from 6 pm to 10 am the next
							day.
						</p>
					</>
				)}

				<p>Let's get you started on your journey towards a healthier you!</p>
			</div>
		);
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getNutritionPlanDetailAPI(
				id,
				onNutritionPlanDetailSuccess,
				onNutritionPlanDetailError,
				onNutritionPlanDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, []);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems}>
			{/* Modal */}
			<nav>
				{/* Delete modal */}
				<div
					class={`modal ${
						selectedNutritionPlanForDeletion !== null ? "is-active" : ""
					}`}>
					<div class="modal-background"></div>
					<div class="modal-card">
						<header class="modal-card-head">
							<p class="modal-card-title">Are you sure?</p>
							<button
								class="delete"
								aria-label="close"
								onClick={(e, ses) =>
									setSelectedNutritionPlanForDeletion(null)
								}></button>
						</header>
						<section class="modal-card-body">
							You are about to delete this nutrition plan and all the data
							associated with it. This action is cannot be undone. Are you sure
							you would like to continue?
						</section>
						<footer class="modal-card-foot">
							<button
								class="button is-success"
								onClick={onDeleteConfirmButtonClick}>
								Confirm
							</button>
							<button
								class="button"
								onClick={(e, ses) => setSelectedNutritionPlanForDeletion(null)}>
								Cancel
							</button>
						</footer>
					</div>
				</div>
			</nav>

			{/* Page */}
			<div class="box">
				{datum && (
					<div class="columns">
						<div class="column">
							<p class="title is-4">
								<FontAwesomeIcon className="fas" icon={faLeaf} />
								&nbsp;Nutrition Plan
							</p>
						</div>
						{datum.status === NUTRITION_GOAL_STATUS_ACTIVE && (
							<div class="column has-text-right">
								<Link
									to={`/nutrition-plan/${id}/update`}
									class="button is-warning is-small is-fullwidth-mobile"
									type="button">
									<FontAwesomeIcon className="mdi" icon={faPencil} />
									&nbsp;Edit & Re-request
								</Link>
								&nbsp;
								<Link
									onClick={(e, s) => {
										setSelectedNutritionPlanForDeletion(datum);
									}}
									class="button is-danger is-small is-fullwidth-mobile"
									type="button">
									<FontAwesomeIcon className="mdi" icon={faTrash} />
									&nbsp;Delete
								</Link>
							</div>
						)}
					</div>
				)}
				<FormErrorBox errors={errors} />

				{/* <p class="pb-4">Please fill out all the required fields before submitting this form.</p> */}

				{isFetching ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					<>
						{datum && (
							<div key={datum.id}>
								{/*
                                      ---------------------------------------------
                                      Pending Status GUI
                                      ---------------------------------------------
                                    */}
								{datum.status === NUTRITION_GOAL_STATUS_PENDING && (
									<section className="hero is-medium has-background-white-ter">
										<div className="hero-body">
											<p className="title">
												<FontAwesomeIcon className="fas" icon={faClock} />
												&nbsp;Nutrition Plan Pending
											</p>
											<p className="subtitle">
												You have successfully submitted this nutrition plan to
												our team. The estimated time until our team completes
												your nutrition plan will take about <b>1 or 2 days</b>.
												Please check back later.
											</p>
										</div>
									</section>
								)}

								{/*
                                      ---------------------------------------------
                                      Queued Status GUI
                                      ---------------------------------------------
                                    */}
								{datum.status === NUTRITION_GOAL_STATUS_QUEUED && (
									<section className="hero is-medium has-background-warning-light">
										<div className="hero-body">
											<p className="title">
												<FontAwesomeIcon className="fas" icon={faClock} />
												&nbsp;Nutrition Plan Not Ready
											</p>
											<p className="subtitle">
												Your nutrition plan has been queued for processing. Our
												team will start working on it soon.
											</p>
										</div>
									</section>
								)}

								{/*
                                      ---------------------------------------------
                                      Active Status GUI
                                      ---------------------------------------------
                                    */}
								{datum.status === NUTRITION_GOAL_STATUS_ACTIVE && (
									<>
										{/* Tab navigation */}

										<div class="tabs is-medium is-size-7-mobile">
											<ul>
												<li class="is-active">
													<Link>
														<strong>Detail</strong>
													</Link>
												</li>
												<li>
													<Link
														to={`/nutrition-plan/${datum.id}/submission-form`}>
														Submission Form
													</Link>
												</li>
											</ul>
										</div>

										<p class="title is-6">META</p>
										<hr />

										<DataDisplayRowText label="Name" value={datum.name} />

										<p class="title is-6 pt-5">
											<FontAwesomeIcon className="fas" icon={faIdCard} />
											&nbsp;DETAIL
										</p>
										<hr />

										<div>{renderNutritionPlan(datum.dietPlan)}</div>
									</>
								)}

								{/*
                                      ---------------------------------------------
                                      Archived Status GUI
                                      ---------------------------------------------
                                    */}
								{datum.status === NUTRITION_GOAL_STATUS_ARCHIVED && (
									<section className="hero is-medium has-background-grey-lighter">
										<div className="hero-body">
											<p className="title">
												<FontAwesomeIcon className="fas" icon={faArchive} />
												&nbsp;Nutrition Plan Archived
											</p>
											<p className="subtitle">
												This nutrition plan has been archived. It is no longer
												active but can still be viewed.
											</p>
										</div>
									</section>
								)}

								{/*
                                      ---------------------------------------------
                                      In-Progress Status GUI
                                      ---------------------------------------------
                                    */}
								{datum.status === NUTRITION_GOAL_STATUS_IN_PROGRESS && (
									<section className="hero is-medium has-background-info-light">
										<div className="hero-body">
											<p className="title">
												<FontAwesomeIcon className="fas" icon={faSpinner} />
												&nbsp;Nutrition Plan In Progress
											</p>
											<p className="subtitle">
												Your nutrition plan is currently being prepared. Please
												check back soon for updates.
											</p>
										</div>
									</section>
								)}

								<div class="columns pt-5">
									<div class="column is-half">
										<Link
											class="button is-hidden-touch"
											to={`/nutrition-plans`}>
											<FontAwesomeIcon className="fas" icon={faArrowLeft} />
											&nbsp;Back to nutrition plans
										</Link>
										<Link
											class="button is-fullwidth is-hidden-desktop"
											to={`/nutrition-plans`}>
											<FontAwesomeIcon className="fas" icon={faArrowLeft} />
											&nbsp;Back to nutrition plans
										</Link>
									</div>
									<div class="column is-half has-text-right">
										{datum.status === NUTRITION_GOAL_STATUS_ACTIVE && (
											<>
												<Link
													class="button is-warning is-hidden-touch"
													to={`/nutrition-plan/${id}/update`}>
													<FontAwesomeIcon className="fas" icon={faPencil} />
													&nbsp;Edit & Re-request
												</Link>
												<Link
													class="button is-warning is-fullwidth is-hidden-desktop"
													to={`/nutrition-plan/${id}/update`}>
													<FontAwesomeIcon className="fas" icon={faPencil} />
													&nbsp;Edit & Re-request
												</Link>
											</>
										)}
									</div>
								</div>

								{/*
                                      ---------------------------------------------
                                      Error Status GUI
                                      ---------------------------------------------
                                    */}
								{datum.status === NUTRITION_GOAL_STATUS_ERROR && (
									<>
										<section className="hero is-medium has-background-white-ter">
											<div className="hero-body">
												<p className="title">
													<FontAwesomeIcon className="fas" icon={faClock} />
													&nbsp;Nutrition Plan Problem
												</p>
												<p className="subtitle">
													There appears to be an problem with your fitness plan
													submission. We are investigating and working through
													the issue. Please check in again in another day.
												</p>
											</div>
										</section>
									</>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</Layout>
	);
}

export default MemberNutritionPlanDetail;
