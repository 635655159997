import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faGauge,
  faArrowRight,
  faTable,
  faRefresh,
  faArrowUp,
  faChild,
  faRunning,
  faBolt,
  faHeart,
  faWeight,
  faMapMarkedAlt,
  faShoePrints,
  faUser,
  faTrophy,
  faDumbbell,
  faVideoCamera,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import { faFreeCodeCamp } from "@fortawesome/free-brands-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../Reusable/FormErrorBox";
import { getMySummaryAPI } from "../../API/Biometric";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
} from "../../AppState";
import PageLoadingContent from "../Reusable/PageLoadingContent";
import { formatDateStringWithTimezone } from "../../Helpers/timeUtility";
import AverageAndTimeComparison from "../Reusable/AverageDateAndTimeComparison";
import Layout from "../Menu/Layout";
import OnBoardingQuestionWizard from "../Reusable/Wizard/Wizard";
import { Title, SelectableOption, Card } from "../Reusable/Wizard/Questions";

function MemberDashboard() {
  ////
  //// Global state.
  ////

  const navigate = useNavigate();

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////

  const [errors, setErrors] = useState({});
  const [datum, setDatum] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [isComingSoon, setComingSoon] = useState(true);
  const [answers, setAnswers] = useState({});
  const [forceURL, setForceURL] = useState("");
  const defaultAvatarUrl = "static/default_user_whitebg.png";

  useEffect(() => {
    console.log(answers);
    // Perform any action here when selectedCards changes
    // For example, calling an API based on the new selection
  }, [answers]);

  ////
  //// Event handling.
  ////

  const handleNavigateToAccount = (e) => {
    e.preventDefault();
    navigate("/account", { state: { activeTabProp: "wearableTech" } });
  };

  const getDatum = (user) => {
    if (user !== undefined && user !== null && user !== "") {
      setFetching(true);
      setErrors({});
      getMySummaryAPI(user.id, onSummarySuccess, onSummaryError, onSummaryDone);
    }
  };

  ////
  //// API.
  ////

  // --- SUMMARY --- //

  function onSummarySuccess(response) {
    console.log("onSummarySuccess: Starting...");
    console.log("onSummarySuccess: response:", response);
    setDatum(response);
  }

  function onSummaryError(apiErr) {
    console.log("onSummaryError: Starting...");
    setErrors(apiErr);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onSummaryDone() {
    console.log("onSummaryDone: Starting...");
    setFetching(false);
  }

  ////
  //// BREADCRUMB
  ////
  const breadcrumbItems = {
    items: [{ text: "Dashboard", link: "#", isActive: true, icon: faGauge }],
  };

  ////
  //// Misc.
  ////
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.

      // if (currentUser && !forceURL) {
      //     if (!currentUser.onboardingCompleted && currentUser.role === 4) {
      //       setForceURL("/onboarding");
      //     }
      // }

      getDatum(currentUser);
    }

    return () => {
      mounted = false;
    };
  }, [currentUser, forceURL]);

  if (forceURL !== undefined && forceURL !== null && forceURL !== "") {
    console.log("Redirecting to:", forceURL);
    return <Navigate to={forceURL} />;
  }
  const connectedApps = currentUser.healthAppConnections;

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      {/* Wizard Component */}
      {/* <OnBoardingQuestionWizard questions={questionsData} /> */}

      <div className="box">
        <div className="columns">
          <div className="column">
            <h1 className="title is-4">
              <FontAwesomeIcon className="fas" icon={faGauge} />
              &nbsp;Dashboard
            </h1>
          </div>
          <div className="column has-text-right">
            <button
              onClick={() => getDatum(currentUser)}
              class="is-fullwidth-mobile button is-link is-small"
              type="button"
            >
              <FontAwesomeIcon className="mdi" icon={faRefresh} />
              &nbsp;
              <span class="is-hidden-desktop is-hidden-tablet">Refresh</span>
            </button>
            {/*
                    DEVELOPERS NOTE:
                    - If the logged in user doesn't have a device registered then
                      show the following button to encourage them to register.
                */}
            {currentUser !== undefined &&
              currentUser !== null &&
              currentUser !== "" && (
                <>
                  {(connectedApps === null ||
                    connectedApps.length === 0 ||
                    connectedApps.filter((app) => app.connected === "CONNECTED")
                      .length === 0) && (
                    <>
                      &nbsp;
                      <Link
                        onClick={(e) => handleNavigateToAccount(e)}
                        className="is-fullwidth-mobile button is-small is-success"
                        type="button"
                      >
                        <FontAwesomeIcon className="mdi" icon={faPlus} />
                        &nbsp;Register Wearable
                      </Link>
                    </>
                  )}
                </>
              )}
          </div>
        </div>
        {isFetching ? (
          <PageLoadingContent displayMessage={"Please wait..."} />
        ) : (
          <>
            <FormErrorBox errors={errors} />

            {datum !== undefined && datum !== null && datum !== "" ? (
              <div>
                <div className="columns">
                  <div className="column">
                    <div className="box">
                      <div className="is-flex is-flex-direction-column-mobile is-flex-direction-row-tablet is-flex-direction-row-desktop is-align-items-center">
                        <div className="is-hidden-mobile">
                          <figure
                            className="image is-96x96"
                            style={{ marginRight: "1rem" }}
                          >
                            <img
                              className="is-rounded"
                              src={
                                currentUser &&
                                currentUser.avatarObjectUrl !== "" &&
                                currentUser.avatarObjectUrl !== undefined
                                  ? currentUser.avatarObjectUrl
                                  : defaultAvatarUrl
                              }
                              alt={currentUser.name}
                              style={{
                                objectFit: "cover",
                                height: "96px",
                                width: "96px",
                                borderRadius: "50%",
                                border: "12px solid #44444",
                              }}
                            />
                          </figure>
                        </div>
                        <div>
                          <h5 className="is-size-3 has-text-primary has-text-weight-semibold is-size-5-mobile">
                            {`Hi, ${currentUser.firstName}`}
                          </h5>
                          <p className="is-size-5 is-size-6-mobile">
                            Here is your biometric scores in comparison to
                            yesterday
                            {/* &nbsp; (
														{formatDateStringWithTimezone(
															new Date().toISOString()
														)}
														) */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="columns">
                  <div className="column is-full">
                    <div className="box has-background-light">
                      <div className="columns is-vcentered is-variable is-8">
                        <div className="column is-narrow has-text-centered">
                          <div className="icon-background">
                            <FontAwesomeIcon
                              className="fas has-text-primary is-size-1"
                              icon={faShoePrints}
                            />
                          </div>
                        </div>
                        <div className="column has-text-centered">
                          <h5 className="is-size-1 is-size-3-mobile has-text-weight-semibold">
                            {(datum &&
                              datum.thisIsoWeekSummary &&
                              datum.thisIsoWeekSummary.data &&
                              datum.thisIsoWeekSummary.data.steps) ||
                              0}
                            <span className="is-size-4 is-size-6-mobile">
                              &nbsp;Steps this week
                            </span>
                          </h5>
                          <AverageAndTimeComparison
                            lastDay={
                              datum &&
                              datum.lastIsoWeekSummary &&
                              datum.lastIsoWeekSummary.data
                            }
                            thisDay={
                              datum &&
                              datum.thisIsoWeekSummary &&
                              datum.thisIsoWeekSummary.data
                            }
                            metric="steps"
                            iconState={true}
                            comparisonPeriod="week"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="box bg_brand is-flex is-align-items-center is-flex-direction-column	">
                      <span className="icon-background">
                        <FontAwesomeIcon
                          className="fas px-3 has-text-primary is-size-1"
                          icon={faMapMarkedAlt}
                        />
                      </span>
                      <h5 class="mt-2 is-size-2 has-text-centered has has-text-weight-semibold is-size-3-mobile">
                        {datum &&
                        datum.dailySummary &&
                        datum.dailySummary.data &&
                        datum.dailySummary.data.distance !== undefined
                          ? Math.round(datum.dailySummary.data.distance || 0)
                          : 0}
                        <span className="is-size-6">&nbsp;m</span>
                      </h5>
                      <p class="is-size-4 has-text-weight-semibold is-size-5-mobile">
                        Distance
                      </p>
                      <AverageAndTimeComparison
                        lastDay={
                          datum &&
                          datum.lastDaySummary &&
                          datum.lastDaySummary.data
                        }
                        thisDay={
                          datum && datum.dailySummary && datum.dailySummary.data
                        }
                        metric="distance"
                        iconState={true}
                        comparisonPeriod="day"
                      />
                      <p></p>
                    </div>
                  </div>
                  <div class="column">
                    <div class="box is-flex is-align-items-center is-flex-direction-column	">
                      <span className="icon-background">
                        <FontAwesomeIcon
                          className="fas px-3 has-text-primary is-size-1"
                          icon={faFreeCodeCamp}
                        />
                      </span>
                      <h5 class="mt-2 is-size-2 has-text-centered has has-text-weight-semibold is-size-3-mobile">
                        {datum &&
                        datum.dailySummary &&
                        datum.dailySummary.data &&
                        datum.dailySummary.data.calories !== undefined
                          ? Math.round(datum.dailySummary.data.calories || 0)
                          : 0}

                        <span className="is-size-6">&nbsp;kcal</span>
                      </h5>

                      <p class="is-size-4 is-size-5-mobile has-text-weight-semibold">
                        Calories
                      </p>
                      <AverageAndTimeComparison
                        lastDay={
                          datum &&
                          datum.lastDaySummary &&
                          datum.lastDaySummary.data
                        }
                        thisDay={
                          datum && datum.dailySummary && datum.dailySummary.data
                        }
                        metric="calories"
                        iconState={true}
                        comparisonPeriod="day"
                      />
                      <p></p>
                    </div>
                  </div>
                  <div class="column">
                    <div class="box bg_brand is-flex is-align-items-center is-flex-direction-column	">
                      <span className="icon-background">
                        <FontAwesomeIcon
                          className="fas px-3 has-text-primary is-size-1"
                          icon={faChild}
                        />
                      </span>
                      <h5 class="mt-2 is-size-2 has-text-centered has has-text-weight-semibold is-size-3-mobile">
                        {datum &&
                        datum.dailySummary &&
                        datum.dailySummary.data &&
                        datum.dailySummary.data.steps !== undefined
                          ? Math.round(datum.dailySummary.data.steps || 0)
                          : 0}
                        <span className="is-size-6">&nbsp;steps</span>
                      </h5>
                      <p class="is-size-4 has-text-weight-semibold is-size-5-mobile">
                        Steps Count
                      </p>
                      <AverageAndTimeComparison
                        lastDay={
                          datum &&
                          datum.lastDaySummary &&
                          datum.lastDaySummary.data
                        }
                        thisDay={
                          datum && datum.dailySummary && datum.dailySummary.data
                        }
                        metric="steps"
                        iconState={true}
                        comparisonPeriod="day"
                      />
                      <p></p>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-half">
                    <div class="box has-text-centered hero is-medium is-dark custom-hero">
                      <div class="hero-body">
                        <p class="title">
                          <FontAwesomeIcon
                            className="fas has-text-primary"
                            icon={faLeaf}
                          />
                          <br />
                          Nutrition Plans
                        </p>
                        <p class="subtitle">
                          View your trainer created nutrition plans:
                          <br />
                          <br />
                          <Link
                            className="has-text-white"
                            to={"/nutrition-plans"}
                          >
                            View&nbsp;
                            <FontAwesomeIcon
                              className="fas"
                              icon={faArrowRight}
                            />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="column is-half">
                    <div class="box has-text-centered hero is-medium is-dark custom-hero">
                      <div class="hero-body">
                        <p class="title">
                          <FontAwesomeIcon
                            className="fas has-text-primary"
                            icon={faTrophy}
                          />
                          <br />
                          Fitness Plans
                        </p>
                        <p class="subtitle">
                          View your trainer created fitness plans:
                          <br />
                          <br />
                          <Link
                            className="has-text-white"
                            to={"/fitness-plans"}
                          >
                            View&nbsp;
                            <FontAwesomeIcon
                              className="fas"
                              icon={faArrowRight}
                            />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="box has-text-centered hero is-medium is-dark custom-hero">
                      <div class="hero-body">
                        <p class="title">
                          <FontAwesomeIcon
                            className="fas has-text-primary"
                            icon={faDumbbell}
                          />
                          <br />
                          Exercises
                        </p>
                        <p class="subtitle">
                          View all the exercises to help you at the gym:
                          <br />
                          <br />
                          <Link className="has-text-white" to={"/exercises"}>
                            View&nbsp;
                            <FontAwesomeIcon
                              className="fas"
                              icon={faArrowRight}
                            />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <section className="hero is-medium has-background-white-ter">
                  <div className="hero-body">
                    <p className="title">
                      <FontAwesomeIcon className="fas" icon={faTable} />
                      &nbsp;No Biometrics
                    </p>
                    <p className="subtitle">
                      You currently have no biometrics data.
                      <b>
                        <Link onClick={(e) => handleNavigateToAccount(e)}>
                          Click here&nbsp;
                          <FontAwesomeIcon
                            className="mdi"
                            icon={faArrowRight}
                          />
                        </Link>
                      </b>
                      to get started registering your wearable tech!
                    </p>
                  </div>
                </section>
                <section className="hero mt-5 is-medium has-background-white-ter">
                  <div className="is-medium has-background-white">
                    <div class="columns">
                      <div class="column is-half">
                        <div class="box has-text-centered hero is-medium is-dark custom-hero">
                          <div class="hero-body">
                            <p class="title">
                              <FontAwesomeIcon
                                className="fas has-text-primary"
                                icon={faLeaf}
                              />
                              <br />
                              Nutrition Plans
                            </p>
                            <p class="subtitle">
                              View your trainer created nutrition plans:
                              <br />
                              <br />
                              <Link
                                className="has-text-white"
                                to={"/nutrition-plans"}
                              >
                                View&nbsp;
                                <FontAwesomeIcon
                                  className="fas"
                                  icon={faArrowRight}
                                />
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="column is-half">
                        <div class="box has-text-centered hero is-medium is-dark custom-hero">
                          <div class="hero-body">
                            <p class="title">
                              <FontAwesomeIcon
                                className="fas has-text-primary"
                                icon={faTrophy}
                              />
                              <br />
                              Fitness Plans
                            </p>
                            <p class="subtitle">
                              View your trainer created fitness plans:
                              <br />
                              <br />
                              <Link
                                className="has-text-white"
                                to={"/fitness-plans"}
                              >
                                View&nbsp;
                                <FontAwesomeIcon
                                  className="fas"
                                  icon={faArrowRight}
                                />
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <div class="box has-text-centered hero is-medium is-dark custom-hero">
                          <div class="hero-body">
                            <p class="title">
                              <FontAwesomeIcon
                                className="fas has-text-primary"
                                icon={faDumbbell}
                              />
                              <br />
                              Exercises
                            </p>
                            <p class="subtitle">
                              View all the exercises to help you at the gym:
                              <br />
                              <br />
                              <Link
                                className="has-text-white"
                                to={"/exercises"}
                              >
                                View&nbsp;
                                <FontAwesomeIcon
                                  className="fas"
                                  icon={faArrowRight}
                                />
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
}

export default MemberDashboard;
