import {
  faCalendarAlt,
  faClock,
  faDumbbell,
  faEdit,
  faMarker,
  faNoteSticky,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TabularDesktop = ({
  pointsHistory = [],
  setselectedPOint,
  setshowUpdateModal,
  setshowDeleteModal,
}) => {
  return (
    <>
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>
              Date &nbsp;
              <FontAwesomeIcon icon={faCalendarAlt} />
            </th>
            <th>
              &nbsp; Time <FontAwesomeIcon icon={faClock} />
            </th>
            <th>
              Counts Added &nbsp;
              <FontAwesomeIcon icon={faMarker} />
            </th>
            <th>
              Activity &nbsp;
              <FontAwesomeIcon icon={faDumbbell} />
            </th>
            <th>
              Notes &nbsp;
              <FontAwesomeIcon icon={faNoteSticky} />
            </th>
          </tr>
        </thead>
        <tbody>
          {pointsHistory.map((entry) => (
            <tr key={entry.id}>
              <td>{new Date(entry.date).toLocaleDateString()}</td>
              <td>{new Date(entry.date).toLocaleTimeString()}</td>
              <td>&nbsp;{entry.value}</td>
              <td>&nbsp;{entry.activity}</td>
              <td>&nbsp;{entry.notes}</td>
              <td>
                <div className="buttons is-right">
                  <button className="button is-warning is-small mr-1 ml-5">
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => {
                        setselectedPOint(entry);
                        setshowUpdateModal(true);
                      }}
                    />
                  </button>
                  <button className="button is-danger is-small mr-1 ml-1">
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => {
                        setselectedPOint(entry);
                        setshowDeleteModal(true);
                      }}
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TabularDesktop;
