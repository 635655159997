import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { formatTimeDifferenceSince } from "../../Helpers/timeUtility";

const AverageAndTimeComparison = ({
  lastDay,
  thisDay,
  iconState,
  metric,
  comparisonPeriod,
}) => {
  // If no data is provided, do not render the component.
  if (!lastDay || !thisDay) {
    return null;
  }

  // Ensure metric values are defined, if not, set them to zero
  const lastDayMetric = lastDay[metric] !== undefined ? lastDay[metric] : 0;
  const thisDayMetric = thisDay[metric] !== undefined ? thisDay[metric] : 0;

  // Calculate the difference and determine if it is positive
  const averageDifference = Math.round(thisDayMetric - lastDayMetric);
  const isPositiveDifference = averageDifference > 0;
  const icon = isPositiveDifference ? faArrowUp : faArrowDown;
  const formattedDifference = Math.abs(averageDifference).toLocaleString();

  // Get the start and end dates for the time difference
  const startDate = new Date(lastDay.end);
  const endDate = new Date(thisDay.end);

  // Determine the additional text based on the metric
  let additionalText = "";
  if (metric === "time") {
    // Mode 1: Time difference
    additionalText = formatTimeDifferenceSince(startDate, endDate);
  } else {
    // For metrics like steps, calories, distance
    const comparisonText =
      comparisonPeriod === "day" ? "yesterday" : "last week";
    if (averageDifference > 0) {
      additionalText = `<span class="is-size-6 is-size-7-mobile">${formattedDifference} more than ${comparisonText}</span>`;
    } else if (averageDifference < 0) {
      additionalText = `<span class="is-size-6 is-size-7-mobile">${formattedDifference} less than ${comparisonText}</span>`;
    } else {
      additionalText = `<span class="is-size-6 is-size-7-mobile">the same as ${comparisonText}</span>`;
    }
  }

  return (
    <p className="is-size-6 is-size-6-mobile">
      {iconState && (
        <FontAwesomeIcon
          className={`fas ${
            isPositiveDifference ? "has-text-success" : "has-text-danger"
          } is-size-6 is-size-6-mobile`}
          icon={icon}
        />
      )}
      &nbsp;
      <span
        dangerouslySetInnerHTML={{
          __html: metric === "time"
            ? `${formattedDifference} ${additionalText}`
            : additionalText,
        }}
      />
    </p>
  );
};

export default AverageAndTimeComparison;
